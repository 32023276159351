.ErrorMessage .ErrorMessage-header {
  padding: 0 0 4vw;
  background-color: var(--color-red);
  color: #fff;
  font-size: 5vw;
  text-align: center;
}

.ErrorMessage .ErrorMessage-message {
  margin: 3vw 0;
}

.ErrorMessage-closeButton {
  color: #fff;
  float: right;
  font-size: 7vw;
  font-weight: bold;
  cursor: pointer;
}

.ErrorMessage-closeButton:hover,
.ErrorMessage-closeButton:focus {
  color: #000;
}

@media screen and (min-width: 768px) {
  .ErrorMessage .ErrorMessage-header {
    padding: 0 0 25px;
    font-size: 40px;
  }

  .ErrorMessage .ErrorMessage-message {
    margin: 20px 0;
  }

  .ErrorMessage-closeButton {
    font-size: 35px;
  }
}
