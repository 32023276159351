.TimeRangeSlider {
  width: 100%;
  font-size: 4vw;
  margin-top: 12vw;
  margin-bottom: 7vw;
}

.TimeRangeSlider-slider {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.TimeRangeSlider-sliderRangeLabel {
  font-size: 4vw;
  color: var(--color-grey--secondary);
  padding-bottom: 1vw;
}

.TimeRangeSlider-sliderRangeLabel--disabled {
  color: var(--color-grey--disabled);
}

.TimeRangeSlider-leftSliderRangeLabel {
  margin-right: 4vw;
}

.TimeRangeSlider-rightSliderRangeLabel {
  margin-left: 4vw;
}

.TimeRangeSlider .rc-slider-handle {
  margin-top: -1.75vw;
}

.TimeRangeSlider .TimeRangeSlider-checkboxSvg {
  width: 5vw;
  height: 5vw;
}

.TimeRangeSlider .TimeRangeSlider-checkbox.TimeRangeSlider-checkbox--checked {
  color: var(--color-red);
}

@media screen and (min-width: 550px) {
  .TimeRangeSlider {
    margin-top: 50px;
  }
}

@media screen and (min-width: 768px) {
  .TimeRangeSlider {
    font-size: 35px;
    margin-bottom: 42px;
  }
  .TimeRangeSlider-label {
    font-size: 35px;
  }
  .TimeRangeSlider-sliderRangeLabel {
    font-size: 28px;
    padding-bottom: 5px;
  }
  .TimeRangeSlider .rc-slider-handle {
    margin-top: -14px;
  }
  .TimeRangeSlider .TimeRangeSlider-checkboxSvg {
    width: 34px;
    height: 34px;
  }
}
