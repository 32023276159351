.SpacesContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 5vw;
}

@media screen and (min-width: 768px) {
  .SpacesContainer {
    padding: 36px;
  }
}
