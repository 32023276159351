:root {
  --color-red: #cc1c29;
  --color-darkRed: #b30310;
  --color-green: #67951f;
  --color-darkGreen: #4e7c06;
  --color-blue: #0090bc;
  --color-yellow: #ffeb3b;
  --color-darkYellow: #e6d222;
  --color-darkGrey: #3f3f3f;
  --color-grey: #eeeeee;
  --color-grey--primary: rgba(0, 0, 0, 0.87);
  --color-grey--secondary: rgba(0, 0, 0, 0.54);
  --color-grey--disabled: rgba(0, 0, 0, 0.38);
  --color-grey--boxShadow: rgba(0, 0, 0, 0.24);
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: 'Roboto', sans-serif;
  color: #000;
}

a {
  text-decoration: none;
}

button {
  background-color: transparent;
  outline: none;
  border: none;
}

div {
  margin: 0;
  padding: 0;
  border: 0;
}

/* Prevent accessibility zoom on iOS */

input {
  font-size: 16px;
}

/* Prevent image stretching in IE11 */

img {
  flex-shrink: 0;
}
