.ReservationDayPicker {
  display: flex;
  height: 6vw;
  max-height: 45px;
}

.ReservationDayPicker ::selection {
  background: transparent;
}

.ReservationDayPicker .react-date-picker--disabled {
  background-color: transparent;
  color: unset;
  opacity: 0.2;
}

.ReservationDayPicker .react-date-picker__wrapper {
  border: none;
}

.ReservationDayPicker .react-date-picker__inputGroup {
  align-items: center;
  justify-content: space-around;
}

.ReservationDayPicker .react-date-picker__inputGroup__input {
  font-size: 5vw;
  font-weight: 500;
  color: #000;
  width: 7vw !important;
  text-align: center;
  cursor: pointer;
  user-select: none;
}

.ReservationDayPicker .react-date-picker__inputGroup__year {
  width: 13vw !important;
}

.ReservationDayPicker .react-date-picker__inputGroup__divider {
  display: none;
}

.ReservationDayPicker--inAppBar .react-date-picker__inputGroup__input {
  color: #fff;
  outline: none;
}

.ReservationDayPicker--inAppBar .ReservationDayPicker-button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0 1.5vw;
  height: 100%;
}

.ReservationDayPicker--inAppBar .ReservationDayPicker-chevron {
  fill: #fff;
}

.ReservationDayPicker--inReservationSeries {
  display: flex;
  align-items: center;
}

.ReservationDayPicker--inReservationSeries .ReservationDayPicker-button {
  color: #000;
  background-color: transparent;
  border: none;
  outline: none;
  padding-top: 0.5vw;
}

.ReservationDayPicker-chevron {
  width: 2vw;
  height: 3vw;
}

.ReservationDayPicker-button {
  display: flex;
  align-items: center;
}

.ReservationDayPicker-button[disabled] {
  opacity: 0.2;
  cursor: default;
}

.ReservationDayPicker__dayLetter {
  display: flex;
  align-items: center;
}

.ReservationDayPicker .react-date-picker {
  position: initial;
}

.ReservationDayPicker .react-date-picker__calendar {
  left: 0;
  right: 0;
  margin: auto;
}

.ReservationDayPicker--inAppBar .react-date-picker__calendar {
  top: calc(30px + 2vw);
}

.ReservationDayPicker--inReservationSeries .react-date-picker__calendar {
  top: 65vw;
}

.ReservationDayPicker--inReservationSeries.ReservationDayPicker--seriesModeEnabled
  .react-date-picker__calendar {
  top: 70vw;
}

@media screen and (min-width: 420px) {
  .ReservationDayPicker--inAppBar .react-date-picker {
    position: relative;
  }

  .ReservationDayPicker .react-date-picker__calendar {
    width: 85vw;
    max-width: 500px;
  }

  .ReservationDayPicker--inAppBar .react-date-picker__calendar {
    top: 100%;
    left: initial;
    right: 0;
  }

  .ReservationDayPicker .react-calendar {
    width: 85vw;
    max-width: 500px;
  }

  .ReservationDayPicker .react-calendar button {
    font-size: 3vw;
  }

  .ReservationDayPicker .react-date-picker__inputGroup__input {
    font-size: 4vw;
    width: 6vw !important;
  }

  .ReservationDayPicker .react-date-picker__inputGroup__year {
    width: 10vw !important;
  }
}

@media screen and (min-width: 550px) {
  .ReservationDayPicker--inReservationSeries .ReservationDayPicker-date {
    font-size: 28px;
    padding: 0 8px;
  }

  .ReservationDayPicker--inReservationSeries .ReservationDayPicker-button {
    font-size: 25px;
    padding-top: 0.2vw;
  }

  .ReservationDayPicker .react-calendar button {
    font-size: 18px;
  }

  .ReservationDayPicker--inReservationSeries .react-date-picker__calendar {
    top: calc(250px + 26vw);
  }

  .ReservationDayPicker--inReservationSeries.ReservationDayPicker--seriesModeEnabled
    .react-date-picker__calendar {
    top: calc(270px + 28vw);
  }
}

@media screen and (min-width: 768px) {
  .ReservationDayPicker--inAppBar .ReservationDayPicker-date {
    font-size: 28px;
    padding: 0 15px;
  }

  .ReservationDayPicker--inAppBar .ReservationDayPicker-button {
    padding: 0 8px;
  }

  .ReservationDayPicker-chevron {
    width: 15px;
    height: 24px;
  }

  .ReservationDayPicker .react-date-picker__inputGroup__input {
    font-size: 28px;
    width: 50px !important;
  }

  .ReservationDayPicker .react-date-picker__inputGroup__year {
    width: 80px !important;
  }

  .ReservationDayPicker--inReservationSeries .react-date-picker__calendar {
    top: 415px;
  }

  .ReservationDayPicker--inReservationSeries.ReservationDayPicker--seriesModeEnabled
    .react-date-picker__calendar {
    top: 450px;
  }
}
