.SpaceReservation {
  padding: 5vw;
}

.SpaceReservation-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.SpaceReservation-header {
  font-size: 7vw;
  font-weight: 500;
}

.SpaceReservation-header,
.SpaceReservation-spaceNumber {
  margin: 2vw 0 3vw;
}

.SpaceReservation-detailsBox {
  display: flex;
  justify-content: space-between;
  margin: 3vw 0;
}

.SpaceReservation-datePickersContainer {
  display: flex;
  flex-direction: column;
}

.SpaceReservation-datePickerWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 4vw;
}

.SpaceReservation-datePickerLabel {
  margin-right: 2vw;
}

.SpaceReservation-details {
  font-size: 6vw;
  display: flex;
  align-items: center;
}

.SpaceReservation-detailsImg {
  width: 8vw;
  height: 8vw;
  margin-right: 2vw;
}

.SpaceReservation-userName {
  font-size: 6vw;
  margin-bottom: 6vw;
}

.SpaceReservation-errorMessage {
  color: var(--color-red);
  margin: 0 0 8vw;
  font-size: 3vw;
}

.SpaceReservation-mainActions {
  display: flex;
  justify-content: space-between;
}

.SpaceReservation-mainActions > .Button {
  margin-right: 2vw;
}

.SpaceReservation-mainActions > .Button:last-child {
  margin-right: 0;
}

@media screen and (min-width: 550px) {
  .SpaceReservation {
    padding: 50px;
  }

  .SpaceReservation-title {
    flex-direction: column;
    align-items: flex-start;
  }

  .SpaceReservation-header {
    font-size: 40px;
  }

  .SpaceReservation-header,
  .SpaceReservation-spaceNumber {
    margin-top: 12px;
    margin-bottom: 18px;
  }

  .SpaceReservation-detailsBox {
    margin: 20px 0;
  }

  .SpaceReservation-detailsBox--seriesModeEnabled {
    padding: 0;
  }

  .SpaceReservation-details {
    font-size: 28px;
  }

  .SpaceReservation-userName {
    font-size: 28px;
    margin-bottom: 25px;
  }

  .SpaceReservation-detailsImg {
    width: 50px;
    height: 50px;
    margin-right: 20px;
  }

  .SpaceReservation-errorMessage {
    margin-bottom: 40px;
    font-size: 18px;
  }
}

@media screen and (min-width: 768px) {
  .SpaceReservation-detailsBox--seriesModeEnabled {
    padding: 0 15px;
  }

  .SpaceReservation-datePickerWrapper {
    font-size: 25px;
  }

  .SpaceReservation-datePickerLabel {
    margin-right: 15px;
  }

  .SpaceReservation-mainActions > .Button {
    margin-right: 15px;
  }
}
