.Login {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Login-akraLogo {
  width: 50%;
  max-width: 350px;
  margin: 15vw 0 0;
}

.Login-spacesTitle {
  font-size: 6vw;
  margin-bottom: 10vw;
}

@media screen and (min-width: 600px) {
  .Login-akraLogo {
    margin: 70px 0 0;
  }

  .Login-spacesTitle {
    font-size: 25px;
    margin-bottom: 50px;
  }
}
