.MainLayout-tabContent {
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
}
.MainLayout-offlineMessage {
  text-align: center;
  padding: 1vw;
  background-color: #fffae1;
  color: #523400;
}

@media screen and (min-width: 768px) {
  .MainLayout-offlineMessage {
    padding: 6px;
  }
}
