.NotFound {
  font-weight: 500;
  font-size: 5vw;
  margin: 5vw 3vw 4vw;
}

@media screen and (min-width: 768px) {
  .NotFound {
    font-size: 40px;
    margin: 50px 30px 40px;
  }
}
