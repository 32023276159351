.AddToHomescreen .AddToHomescreen-header {
  padding: 0 0 4vw;
  background-color: var(--color-red);
  color: #fff;
  font-size: 5vw;
  text-align: center;
}

.AddToHomescreen .AddToHomescreen-message {
  margin: 3vw 0;
}

.AddToHomescreen-closeButton {
  color: #fff;
  float: right;
  font-size: 7vw;
  font-weight: bold;
  cursor: pointer;
}

.AddToHomescreen-closeButton:hover,
.AddToHomescreen-closeButton:focus {
  color: #000;
}

.AddToHomescreen-safariShareIcon {
  width: 5.7vw;
  height: 8vw;
  max-width: 29px;
  max-height: 40px;
  transform: translateY(0.4rem);
  margin: 0 8px;
}

@media screen and (min-width: 768px) {
  .AddToHomescreen .AddToHomescreen-header {
    padding: 0 0 25px;
    font-size: 40px;
  }

  .AddToHomescreen .AddToHomescreen-message {
    margin: 20px 0;
  }

  .AddToHomescreen-closeButton {
    font-size: 35px;
  }
}
