/* Source: https://www.w3schools.com/howto/howto_js_off-canvas.asp */

/* The side navigation menu */
.Sidenav {
  width: 70vw;
  max-width: 500px;
  height: 100%;
  padding-bottom: 6vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Sidenav-header {
  padding: 6vw;
  background-color: var(--color-blue);
  color: #fff;
  margin-bottom: 3vw;
}

.Sidenav-akraLogo {
  width: 50%;
}

.Sidenav-spacesTitle {
  margin: 1vw 0 4vw;
  font-size: 5vw;
}

.Sidenav-greeting {
  font-size: 3vw;
  margin: 0;
  font-weight: 500;
}

.Sidenav-closeButton {
  position: absolute;
  top: 6vw;
  right: 6vw;
  font-size: 8vw;
  width: 6vw;
  height: 6vw;
  color: #fff;
  display: flex;
  flex-direction: column;
  padding: 0;
  justify-content: center;
  align-items: center;
}

.Sidenav-content {
  background-color: #fff;
  color: var(--color-blue);
  width: 100%;
  padding: 0 4vw;
}

.Sidenav-languageSelection {
  display: flex;
  justify-content: space-around;
  margin-bottom: 2vw;
}

.Sidenav-languageSelection button {
  width: 12vw;
}

.Sidenav-languageSelection img {
  border: 1px solid var(--color-grey--boxShadow);
}

.Sidenav-actionButton {
  width: 100%;
  height: 10vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-transform: uppercase;
  color: inherit;
  padding-left: 5%;
  font-size: 0.8rem;
}

.Sidenav-actionButton:hover,
.Sidenav-actionButton:focus {
  background-color: var(--color-grey);
  transition: all 0.3s;
}

.Sidenav-actionButtonImage {
  height: 10vw;
  width: 10vw;
  margin-right: 5%;
}

@media screen and (min-width: 768px) {
  .Sidenav {
    padding-bottom: 50px;
  }
  .Sidenav-header {
    padding: 50px;
    margin-bottom: 50px;
  }
  .Sidenav-spacesTitle {
    margin: 15px 0 30px;
    font-size: 30px;
  }
  .Sidenav-greeting {
    font-size: 24px;
  }
  .Sidenav-languageSelection {
    margin-bottom: 30px;
  }
  .Sidenav-languageSelection button {
    width: 70px;
    height: 45px;
  }
  .Sidenav-closeButton {
    top: 35px;
    right: 50px;
    font-size: 60px;
    width: 50px;
    height: 50px;
  }
  .Sidenav-content {
    padding: 0 50px;
  }
  .Sidenav-actionButton {
    height: 70px;
    padding-left: 10px;
    font-size: 20px;
  }
  .Sidenav-actionButtonImage {
    height: 70px;
    width: 70px;
  }
}

@media screen and (max-height: 600px) {
  .Sidenav-header {
    margin-bottom: 1vw;
  }
}
