.PlanSpace {
  stroke-width: 1;
  stroke: black;
}

.PlanSpace.PlanSpace--freeSpace {
  fill: var(--color-green);
}

.PlanSpace.PlanSpace--freeSpace:hover {
  fill: var(--color-darkGreen);
  cursor: pointer;
}

.PlanSpace.PlanSpace--limitedSpace {
  fill: var(--color-yellow);
}

.PlanSpace.PlanSpace--limitedSpace:hover {
  fill: var(--color-darkYellow);
  cursor: pointer;
}

.PlanSpace.PlanSpace--busySpace {
  fill: var(--color-red);
}

.PlanSpace.PlanSpace--busySpace:hover {
  fill: var(--color-darkRed);
  cursor: pointer;
}

.PlanSpace-text {
  fill: black;
  font-size: 6vw;
}

@media screen and (min-width: 768px) {
  .PlanSpace-text {
    font-size: 24px;
  }
}
