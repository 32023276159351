.EmployeesDropdown {
  margin-bottom: 7vw;
}

.EmployeesDropdown-label {
  font-size: 5.5vw;
  margin-bottom: 15px;
}

.EmployeesDropdown .Select-control {
  overflow: initial;
}

.EmployeesDropdown .Select-clear-zone {
  width: 3.3em;
  transform: translateY(-125%);
}
/* .EmployeesDropdown .Select-clear-zone:before {
  content: 'Clear';
} */

.EmployeesDropdown .Select-clear {
  display: none;
}

@media screen and (min-width: 400px) {
  .EmployeesDropdown .Select-clear-zone {
    transform: initial;
  }
}

@media screen and (min-width: 768px) {
  .EmployeesDropdown {
    margin-bottom: 50px;
  }
  .EmployeesDropdown-label {
    font-size: 32px;
  }
}
