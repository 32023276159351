.PlanContainer {
  margin-top: 5vw;
}

.PlanContainer-planPicker {
  display: flex;
  justify-content: center;
  margin: 5vw 0;
}

.PlanContainer-planView {
  border: 2px solid black;
}

@media screen and (min-width: 768px) {
  .PlanContainer {
    margin-top: 40px;
  }

  .PlanContainer-planPicker {
    margin: 40px 0;
  }
}
