.Space {
  border: none;
  background-color: #f0f0f0;
  padding: 0;
  margin: 3vw 1vw;
  padding: 2vw;
  height: 40vw;
  width: 40vw;
  max-width: 230px;
  max-height: 230px;
  box-shadow: 0 1vw 1vw #ccc;
  color: white;
  font-size: 6vw;
  font-weight: bold;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
  transition: background-color 0.2s ease-in;
  border-radius: 1vw;
}

.Space:hover {
  background-color: #e5e5e5;
}

.Space:active {
  background-color: #ccc;
}

.Space--freeSpace {
  background-color: var(--color-green);
}

.Space--freeSpace:hover {
  background-color: var(--color-darkGreen);
}

.Space--limitedSpace {
  background-color: var(--color-yellow);
}

.Space--limitedSpace:hover {
  background-color: var(--color-darkYellow);
}

.Space--busySpace {
  background-color: var(--color-red);
}

.Space--busySpace:hover {
  background-color: var(--color-darkRed);
}

.Space img {
  width: auto;
  height: 60%;
  margin-top: 5%;
}

.Space span {
  width: 100%;
  display: block;
}

@media screen and (min-width: 500px) {
  .Space {
    font-size: 32px;
  }
}

@media screen and (min-width: 768px) {
  .Space {
    width: 200px;
    height: 200px;
    padding: 15px;
    margin: 30px 10px;
    font-size: 30px;
    box-shadow: 0 10px 10px #ccc;
    border-radius: 10px;
  }
}
