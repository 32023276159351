.Favorites {
  margin-bottom: 3vw;
  overflow-y: auto;
}

.Favorites__message {
  color: var(--color-red);
  font-size: 0.75rem;
  padding-left: 2vw;
  margin: 2vw 0;
}

.Favorites__field-wrapper {
  padding-left: 2vw;
}

.Favorites__field-label {
  margin: 2vw 0;
  font-size: 0.75rem;
}

.Favorites__field {
  max-width: 250px;
}

.Favorites__input {
  font-family: inherit;
  padding: 2vw;
  border-color: #d9d9d9 #ccc #b3b3b3;
  border-radius: 4px;
  border: 1px solid #ccc;
  color: #333;
  height: 34px;
}

.Favorites__submit-button {
  width: 100%;
  height: 10vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-transform: uppercase;
  color: inherit;
  padding-left: 5%;
  font-size: 0.8rem;
  cursor: pointer;
  white-space: nowrap;
}

.Favorites__submit-button:hover,
.Favorites__submit-button:focus {
  background-color: var(--color-grey);
  transition: all 0.3s;
}

@media screen and (max-width: 400px) {
  .Favorites__submit-button {
    font-size: 0.7rem;
  }
}

@media screen and (min-width: 768px) {
  .Favorites {
    margin-bottom: 30px;
  }
  .Favorites__field-wrapper {
    padding-left: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .Favorites__field-label {
    margin: 15px 0;
    font-size: 18px;
    display: inline;
  }
  .Favorites__message {
    font-size: 18px;
    padding-left: 10px;
    margin: 10px 0;
  }
  .Favorites__field {
    width: 50%;
    display: inline-block;
  }
  .Favorites__input {
    padding: 8px;
  }
  .Favorites__submit-button {
    height: 70px;
    padding-left: 10px;
    font-size: 20px;
  }
}

@media screen and (max-height: 600px) {
  .Favorites__input {
    height: 24px;
  }
}
