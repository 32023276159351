.AppBar {
  background-color: var(--color-red);
  width: 100%;
  top: 0;
  z-index: 1;
  box-shadow: 0 2vw 2vw #ccc;
}

.AppBar-top {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  max-width: 768px;
  margin: 0 auto;
  padding: 3vw;
}

.AppBar-topButton {
  color: #fff;
  width: 6vw;
  height: 6vw;
  max-width: 50px;
  max-height: 50px;
  font-size: 5vw;
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
}

.AppBar-topButton img {
  width: 100%;
}

.AppBar-topButton--disabled {
  opacity: 0.2;
}

.AppBar-tabs {
  display: flex;
  justify-content: center;
  max-width: 768px;
  margin: 0 auto;
}

.AppBar__message {
  color: var(--color-red);
  margin: 3vw auto 0 auto;
  font-size: 3vw;
  max-width: 768px;
  width: 90%;
}

@media screen and (min-width: 768px) {
  .AppBar-top {
    padding: 20px;
  }
  .AppBar-topButton {
    font-size: 40px;
  }
  .AppBar__message {
    margin-top: 15px;
    font-size: 18px;
  }
}
