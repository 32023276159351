.ReservationsContainer {
  margin-top: 4vw;
}

.ReservationsContainer-reservationsList {
  margin: 3vw;
}

.ReservationsContainer-monthName,
.ReservationsContainer-noReservations {
  font-weight: 500;
  font-size: 5vw;
  margin: 5vw 3vw 4vw;
}

@media screen and (min-width: 768px) {
  .ReservationsContainer-reservationsList {
    margin: 10px;
  }

  .ReservationsContainer {
    margin-top: 40px;
  }

  .ReservationsContainer-monthName,
  .ReservationsContainer-noReservations {
    font-size: 40px;
    margin: 50px 30px 40px;
  }
}
