.Reservation {
  background-color: white;
  border: 0.1vw solid #ddd;
  border-top: 0;
  box-shadow: 0 0.3vw 0.3vw #ccc;
  padding: 2vw 3vw;
  margin: 2vw 0;
  position: relative;
  display: flex;
  flex-direction: column;
}

.Reservation-userDetails {
  margin-bottom: 1vw;
}

.Reservation-userName {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 4vw;
  font-weight: normal;
}

.Reservation-createdBy {
  font-size: 3vw;
  margin: 0;
  font-weight: normal;
}

.Reservation-details {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.Reservation:hover {
  background-color: #eee;
}

.Reservation-leftColumn {
  display: flex;
  align-items: center;
}

.Reservation-space {
  font-size: 4vw;
  margin: 0;
  font-weight: 500;
  color: white;
  border-radius: 50%;
  display: inline-block;
  line-height: 1.6em;
  text-align: center;
  width: 10vw;
  height: 10vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Reservation-space--freeSpace {
  background-color: var(--color-green);
}

.Reservation-space--limitedSpace {
  background-color: var(--color-yellow);
}

.Reservation-space--busySpace {
  background-color: var(--color-red);
}

.Reservation-date {
  border: 0;
  margin: 0;
  color: var(--color-darkGrey);
  font-size: 4vw;
  margin-left: 3vw;
}

.Reservation-time {
  border: none;
  color: #6f6f6f;
  font-size: 3vw;
}

.Reservation-button {
  height: 10vw;
  width: 10vw;
  padding: 0;
  margin: 0 0 0 2vw;
}

.Reservation-buttonsContainer {
  display: flex;
}

.Reservation-buttonImage {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.Reservation-buttonImage:hover {
  background-color: #ddd;
  border-radius: 2vw;
}

@media screen and (min-width: 768px) {
  .Reservation {
    border-width: 2px;
    box-shadow: 0 2px 2px #ccc;
    padding: 10px 25px;
    margin: 10px 0;
  }
  .Reservation-userDetails {
    margin-bottom: 5px;
  }
  .Reservation-userName {
    font-size: 28px;
  }
  .Reservation-createdBy {
    font-size: 20px;
  }
  .Reservation-space {
    font-size: 28px;
    width: 75px;
    height: 75px;
  }
  .Reservation-date {
    font-size: 32px;
    margin-left: 25px;
  }
  .Reservation-time {
    font-size: 20px;
  }
  .Reservation-button {
    width: 70px;
    height: 70px;
    margin: 0 0 0 20px;
  }
  .Reservation-buttonImage:hover {
    border-radius: 20px;
  }
}
