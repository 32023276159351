.Button {
  min-width: 66px;
  min-height: 36px;
  width: 30vw;
  height: 9vw;
  max-width: 190px;
  max-height: 56px;
  box-shadow: 0, 2px, 2px, var(--color-grey--boxShadow);
  font-size: 3.5vw;
  text-transform: uppercase;
  border-radius: 1px;
  font-weight: bold;
  background-color: var(--color-grey);
  color: var(--color-grey--secondary);
  font-family: inherit;
}

.Button:hover {
  background-color: #ddd;
  cursor: pointer;
}

.Button--long {
  max-width: 250px;
}

.Button.Button--active {
  box-shadow: 0, 8px, 8px, var(--color-grey--boxShadow);
  background-color: var(--color-red);
  color: #fff;
}

.Button--tab {
  color: var(--color-grey);
  box-shadow: 0, 8px, 8px, var(--color-grey--boxShadow);
  background-color: var(--color-red);
  min-width: auto;
  width: 32vw;
  max-width: 220px;
  height: auto;
  max-height: 75px;
  font-size: 3vw;
  padding: 0;
}

.Button--tab.Button--active {
  border-bottom: 0.6vw #fff solid;
  color: #fff;
}

@media screen and (min-width: 400px) {
  .Button {
    font-size: 3vw;
    padding: 1vw;
  }
  .Button--tab {
    padding-bottom: 2vw;
    font-size: 3vw;
  }
  .Button--tab--active {
    padding-bottom: 1.4vw;
  }
}

@media screen and (min-width: 768px) {
  .Button {
    font-size: 20px;
    padding: 15px;
  }
  .Button--tab.Button--active {
    border-bottom-width: 5px;
    padding-bottom: 10px;
  }
}
