.GuestEntry-toggleUser {
  display: flex;
  justify-content: space-between;
  font-size: 6vw;
  align-items: center;
  margin-bottom: 7vw;
}

.GuestEntry-input {
  border: none;
  border-bottom: 0.5vw dotted var(--color-grey);
  background-color: transparent;
  font-size: 5vw;
  outline: none;
  padding-bottom: 2vw;
  caret-color: var(--color-blue);
  margin-bottom: 6vw;
  width: 100%;
}

.GuestEntry-input::-webkit-input-placeholder {
  color: var(--color-grey--boxShadow);
}

.GuestEntry-input:-ms-input-placeholder {
  color: var(--color-grey--boxShadow);
}

.GuestEntry-input::-ms-input-placeholder {
  color: var(--color-grey--boxShadow);
}

.GuestEntry-input::placeholder {
  color: var(--color-grey--boxShadow);
}

.GuestEntry-input--active {
  border-bottom: 0.3vw solid var(--color-red);
}

@media screen and (min-width: 768px) {
  .GuestEntry-toggleUser {
    font-size: 35px;
    margin-bottom: 50px;
  }

  .GuestEntry-input {
    font-size: 32px;
    padding-bottom: 15px;
    margin-bottom: 45px;
    border-bottom-width: 5px;
  }
}
