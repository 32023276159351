.ExistingSpaceReservations {
  margin-bottom: 12vw;
}

.ExistingSpaceReservations-reservationEntry {
  color: var(--color-grey--secondary);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 3.8vw;
  margin-bottom: 1vw;
}

.ExistingSpaceReservations-name {
  width: 45vw;
  text-align: left;
  font-size: 3.5vw;
  margin: 0 4vw;
}

.ExistingSpaceReservations-name--small {
  font-size: 3vw;
}

.ExistingSpaceReservations-button {
  height: 6vw;
  width: 6vw;
  padding: 0;
}

.ExistingSpaceReservations-button + .ExistingSpaceReservations-button {
  margin-left: 2vw;
}

.ExistingSpaceReservations-buttonImage {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.ExistingSpaceReservations-buttonImage:hover {
  background-color: #ddd;
  border-radius: 1vw;
}

@media screen and (min-width: 550px) {
  .ExistingSpaceReservations-reservationEntry {
    font-size: 21px;
  }
  .ExistingSpaceReservations-name {
    width: calc(85vw - 260px);
    font-size: 3.2vw;
    margin: 0 4.2vw;
  }
  .ExistingSpaceReservations-button {
    height: 5vw;
    width: 5vw;
  }
  .ExistingSpaceReservations-name--small {
    font-size: 2.6vw;
  }
  .ExistingSpaceReservations {
    margin-bottom: 60px;
  }
  .ExistingSpaceReservations-button + .ExistingSpaceReservations-button {
    margin-left: 1.5vw;
  }
}

@media screen and (min-width: 768px) {
  .ExistingSpaceReservations-reservationEntry {
    margin-bottom: 5px;
  }
  .ExistingSpaceReservations-name {
    width: 350px;
    font-size: 20px;
    margin: 0 45px;
  }
  .ExistingSpaceReservations-name--small {
    font-size: 19px;
  }
  .ExistingSpaceReservations-button {
    width: 35px;
    height: 35px;
    margin: 8px;
  }
  .ExistingSpaceReservations-buttonImage:hover {
    border-radius: 10px;
  }
  .ExistingSpaceReservations-button + .ExistingSpaceReservations-button {
    margin-left: 10px;
  }
}
