.Spinner {
  border: 16px solid #feb7bc;
  border-radius: 50%;
  border-top: 16px solid #b30310;
  width: 120px;
  height: 120px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -60px; /*set to a negative number 1/2 of your height*/
  margin-left: -60px;
  z-index: 9999 !important;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.Spinner-overlay {
  background: #000000;
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.5;
  z-index: 15;
  touch-action: none;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
